import React from 'react';

interface IPageTitleProps {
    title: string;
}

const PageTitle: React.FC<IPageTitleProps> = ({ title, children }) => {
    return <div className="page-title-wrapper" >
        <h1 className="page-title">{title}</h1>
        { children }
    </div>
}

export default PageTitle;