import React from 'react';
import backpack from '../assets/images/mask_default.png';
import experimental from '../assets/images/mask_experimental.png';
import imaginative from '../assets/images/mask_imaginative.png';
import expressive from '../assets/images/mask_expressive.png';
import knowledge from '../assets/images/mask_knowledge.png';
import nifty from '../assets/images/mask_nifty.png';
import ReactFullpage from '@fullpage/react-fullpage';
import ReactImageAppear from 'react-image-appear';

const mobileContent = [
    {
        image: imaginative,
        title: "Imaginative",
    },
    {
        image: nifty,
        title: "Nifty",
    },
    {
        image: experimental,
        title: "experimental",
    },
    {
        image: knowledge,
        title: "knowledge",
    },
    {
        image: expressive,
        title: "expressive",
    }
]

const name = ['I', 'N', 'E', 'K', 'E'];

const HomeDesktop: React.FC = () => {
    return <ReactFullpage
    licenseKey = {'858F71E2-73D94C8E-8BA2EC48-A85813E6'}
    scrollingSpeed = {750}
    navigation
    render={({ state, fullpageApi }) => {
        return (
            <div id="fullpage-wrapper">
                <div className="section">
                    <div className="home-wrapper-desktop">
                        <div className="left-container">
                            <div className="name-container">
                                <h1>INEKE</h1>
                                <h1>DEBO</h1>
                            </div>
                            <span className="subtitle">More than just a designer</span>
                        </div>
                        <div className="right-container">
                            <ReactImageAppear
                                className="main-image"
                                src={backpack}
                                showLoader={false}
                                alt="me"
                                animationDuration="0.5s"
                                placeholderStyle={{ backgroundColor: 'initial' }}
                            />
                        </div>
                    </div>
                </div>
                {mobileContent.map((el, key) => {
                    return <div key={key} className="section">
                        <div className="home-wrapper-desktop">
                         <div className="left-container">
                            <div className="ineke-title">
                                {name.map((char, keyEl) => {
                                    if (key === keyEl) {
                                        return <span className="char black">{el.title}</span>
                                    }
                                    return <span className="char">{char}</span>
                                })}
                            </div>
                        </div>
                            <div className="right-container">
                                <ReactImageAppear
                                    className="main-image"
                                    src={el.image}
                                    showLoader={false}
                                    alt="me"
                                    animationDuration="0.5s"
                                    placeholderStyle={{ backgroundColor: 'initial' }}
                                />
                            </div>
                        </div>
                    </div>
                })}
            </div>
        );
    }}
    />
};

export default HomeDesktop;