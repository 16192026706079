import React from 'react';
import useWindowDimensions from "../hooks/WindowDimensions";
import '../styles/_home.scss';
import HomeMobile from '../Components/HomeMobile';
import HomeDesktop from '../Components/HomeDesktop';
import {Animate} from "react-show";

const Home: React.FC = () => {
    const { width } = useWindowDimensions();

    if (width <= 1224) {
        return <Animate
        show={true || false}
        transitionOnMount
        duration={400}
        style={{
            opacity: 1
        }}
        start={{
            opacity: 0
        }}
        enter={{
            opacity: 1
        }}
        leave={{
            opacity: 1
        }}
    >
        <HomeMobile/>
    </Animate>
    }

    return <Animate
        show={true || false}
        transitionOnMount
        duration={400}
        style={{
            opacity: 1
        }}
        start={{
            opacity: 0
        }}
        enter={{
            opacity: 1
        }}
        leave={{
            opacity: 1
        }}
    >
        <HomeDesktop/>
    </Animate>
};

export default Home;