import * as React from "react";
import {useRef, useState} from "react";
import '../styles/_nav.scss'
import { Link } from "react-router-dom";
import useWindowDimensions from "../hooks/WindowDimensions";
import {useEffect} from 'react';

export const Nav = () => {
    const navRef = useRef(null);
    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [navRef]);

    function handleClickOutside(event) {
        //@ts-ignore
        if (navRef.current && !navRef?.current?.contains(event.target)) {
            setExpand(false);
        }
    }

    const [expand, setExpand] = useState(false);
    const { width } = useWindowDimensions();

    if (width <= 768) {
        return <div>
            <div className={expand ? "mobile-nav open" : "mobile-nav"}>
                <div className="nav-links-wrapper">
                    <Link className="nav-link" onClick={() => setExpand(!expand)} to="/">Home </Link>
                    <Link className="nav-link" onClick={() => setExpand(!expand)} to="/latestwork">Latest work</Link>
                    <Link className="nav-link" onClick={() => setExpand(!expand)} to="/about">About me</Link>
                </div>
            </div>
            <div className={ expand ? "hamburger open" : "hamburger"} onClick={() => setExpand(!expand)}>
                <span className="hamburger__patty"></span>
                <span className="hamburger__patty"></span>
                <span className="hamburger__patty"></span>
            </div>
        </div>;
    }


    return <div ref={navRef} className={expand ? 'sidebar-open' : 'sidebar-closed'}>
        <div className={expand ? 'sidebar-content-open' : 'sidebar-content-closed' }>
            <button className="icon-button" onClick={() => setExpand(!expand)}>
                <span className="topbar"/>
                <span className="midbar"/>
                <span className="bottombar"/>
            </button>
            <div className={expand ? 'nav-links nav-links-open' : 'nav-links nav-links-closed'}>
                <Link className="nav-link " onClick={() => setExpand(!expand)} to="/">Home </Link>
                <Link className="nav-link" onClick={() => setExpand(!expand)} to="/latestWork">Latest work</Link>
                <Link className="nav-link" onClick={() => setExpand(!expand)} to="/about">About me</Link>
            </div>
        </div>
    </div>
    ;
};

export default Nav;
