import React, {useState} from 'react';
import '../styles/_latestWork.scss';
import LatestWorkBox from "../Components/LatestWorkBox";
import {Animate} from "react-show";
import PageTitle from '../Components/PageTitle';
import test from '../assets/test/content.json';
import content from '../assets/prod/content.json';

const LatestWork: React.FC = () => {
    const [projects] = useState(process.env.REACT_APP_ENV === 'test' ? test : content);

    return <div className="latest-work-wrapper">
        <PageTitle title="Latest work" />
        <Animate
            show={true || false}
            transitionOnMount
            duration={400}
            style={{
                opacity: 1
            }}
            start={{
                opacity: 0
            }}
            enter={{
                opacity: 1
            }}
            leave={{
                opacity: 1
            }}
        >
            <div className="projects-wrapper">
                {projects?.projects.map((project, id) => {
                    return <LatestWorkBox key={project.title} project={project} projectId={id}/>
                })}
            </div>
        </Animate>
    </div>
};

export default LatestWork;
