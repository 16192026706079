import React from 'react';
import {Link} from "react-router-dom";
import { IoIosArrowBack } from 'react-icons/io';

interface IBackLinkProps {
    path: string;
    name: string;
}

const BackLink: React.FC<IBackLinkProps> = ({ path, name }) => {
    return <>
        <IoIosArrowBack className="back-link-icon" />
            <Link to={path} className="back-link">
            Back to {name}
        </Link>
    </>
};

export default BackLink;