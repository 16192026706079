import React from 'react';
import backpack from '../assets/images/mask_default.png';
import experimental from '../assets/images/mask_experimental.png';
import imaginative from '../assets/images/mask_imaginative.png';
import expressive from '../assets/images/mask_expressive.png';
import knowledge from '../assets/images/mask_knowledge.png';
import nifty from '../assets/images/mask_nifty.png';
import ReactFullpage from '@fullpage/react-fullpage';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ReactImageAppear from 'react-image-appear';

const mobileContent = [
    {
        image: backpack,
        title: "Ineke Debo",
        text: "More than just a designer"
    },
    {
        image: imaginative,
        title: "Imaginative",
        text: "",
    },
    {
        image: nifty,
        title: "Nifty",
        text: "",
    },
    {
        image: experimental,
        title: "experimental",
        text: "",
    },
    {
        image: knowledge,
        title: "knowledge",
        text: "",
    },
    {
        image: expressive,
        title: "expressive",
        text: "",
    }
]

const HomeMobile: React.FC = () => {
    return <ReactFullpage
    licenseKey = {'858F71E2-73D94C8E-8BA2EC48-A85813E6'}
    scrollingSpeed = {750}
    render={({ state, fullpageApi }) => {
        return (
            <div id="fullpage-wrapper">
                {mobileContent.map((el, key) => {
                    return <div key={key} className="section section1">
                        <div className="page-wrapper">
                            <ReactImageAppear
                                className="main-image"
                                src={el.image}
                                showLoader={false}
                                alt="me"
                                animationDuration="0.5s"
                                placeholderStyle={{ backgroundColor: 'initial' }}
                            />
                            <div className="text-container">
                                <h1>{el.title}</h1>
                                <span className="subtitle">{el.text}</span>
                            </div>
                            {(key + 1) === mobileContent.length
                            ? <div className="next-block" onClick={() => fullpageApi.moveTo(1)}>
                                <IoIosArrowUp/>
                            </div>
                            : <div className="next-block" onClick={() => fullpageApi.moveSectionDown()}>
                                 <IoIosArrowDown/>
                            </div>
                            }
                        </div>
                    </div>
                })}
            </div>
        );
    }}
    />
};

export default HomeMobile;