import React, {useState} from 'react';
import BackLink from "./BackLink";
import PageTitle from "./PageTitle";
import '../styles/_latestWork.scss';
import { IProject } from "../interfaces";
import test from '../assets/test/content.json';
import content from '../assets/prod/content.json';
import ReactPlayer from 'react-player/lazy'

interface ILatestWorkDetailProps {
    projects: any;
    match: any;
}

const LatestWorkDetail: React.FC<ILatestWorkDetailProps> = ({match}) => {
    const projects = process.env.REACT_APP_ENV === 'test' ? test : content;
    const projectId = match.params.id;
    const [project] = useState<IProject>(projects.projects[projectId]);

    if (!project) {
        return <div>Something went wrong</div>;
    }

    return <div className="project-wrapper">
        <PageTitle title={project.title}>
            <BackLink name="overview" path="/latestwork"/>
        </PageTitle>
        <p className="full-description" dangerouslySetInnerHTML={{ __html: project.fullDescription}}></p>
        <div className="image-grid">
            {project.imageCollection.map((obj) => {
                if (obj.column === 1) {
                    return <img style={{ gridColumn: '1 / 7' }} src={obj.images[0]} />
                } else if (obj.column === 2) {
                    if (obj.images[0].includes('youtu.be')) {
                        return <>
                            <div className="player-1"><ReactPlayer controls={false} width="auto" height="360px" url={obj.images[0]} /></div>
                            <div className="player-2"><ReactPlayer controls={false} width="auto" height="360px" url={obj.images[1]} /></div>
                        </>
                    }
                    return <>
                        <img style={{ gridColumn: '1 / 4' }} src={obj.images[0]} />
                        <img style={{ gridColumn: '4 / 7' }} src={obj.images[1]} />
                    </>
                } else if (obj.column === 3) {
                    return <>
                        <img style={{ gridColumn: '1 / 3' }} src={obj.images[0]} />
                        <img style={{ gridColumn: '3 / 5' }} src={obj.images[1]} />
                        <img style={{ gridColumn: '5 / 7' }} src={obj.images[2]} />
                    </>
                }

                return null
            })}
        </div>
    </div>;
}

export default LatestWorkDetail;