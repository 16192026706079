import React from 'react';
import PageTitle from "../Components/PageTitle";
import '../styles/_about.scss';
import { FaLinkedinIn } from 'react-icons/fa';
import { FiFile } from 'react-icons/fi';
import { IoMdMailOpen } from 'react-icons/io';
import myFace from '../assets/images/about-me.png';
import ReactImageAppear from 'react-image-appear';
import Pdf from '../assets/resume.pdf';

const About: React.FC = () => {
return <div className="about-wrapper">
    <div className="content-wrapper">
        <PageTitle title="About me" />
        <div className="content">
            <div className="text">
                <p>Hi, I’m Ineke Debo, a graphic designer and digital artist.
                    My goal in life is to create things that inspire and bring joy to me and others.
                    My passions lie with creating custom designs that fit my client's needs. Being able to see a smile on
                    their faces is what I live for!
                </p>
                <p>
                    I specialize in design and illustrations, as well as a variety of other things.
                    I enjoy all aspects of the creative process and have a deep appreciation for illustration,
                    typography, arts and crafts.
                </p>
                <p>
                    Where I grew up, hard work got rewarded. My dad taught me that hard work will get you far
                    in life, and that’s a philosophy I embrace.
                </p>
                <p>
                    By day I work as a graphic designer, by night (and by weekend) I work on personal projects like creating illustrations for me and others, creating costumers or props.
                </p>
                <p>
                    In my free time I also draw commissions for people who have their own characters. I’m a self taught artists who’s passionate about
                    learning new techniques and looking for new ways to expand my style.
                </p>
            </div>
            <div className="icon-wrapper">
                <a href="https://linkedin.com/in/ineke-debo-7669951a4/" className="icon-button-wrapper">
                    <FaLinkedinIn />
                </a>
                <a href="mailto:inekedebo@hotmail.com" className="icon-button-wrapper">
                    <IoMdMailOpen />
                </a>
                <a href={Pdf} target="_blank" rel="noopener noreferrer" className="icon-button-wrapper">
                    <FiFile />
                </a>
            </div>
        </div>
        <div className="image">
            <ReactImageAppear
                src={myFace}
                showLoader={false}
                alt="me"
                animationDuration="0.5s"
                placeholderStyle={{ backgroundColor: 'initial' }}
            />
        </div>
    </div>
</div>
};

export default About;