import React from 'react';
import '../App.scss';
import Nav from '../Components/Nav';
import About from './About';
import Home from './Home';
import LatestWork from './LatestWork';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import LatestWorkDetail from "../Components/LatestWorkDetail";

const App = () => {

    const getProjects = () => {
        fetch('https://michvane.github.io/test/content.json')
            .then(response => response.json());
    }
  return <BrowserRouter>
        <div className="App">
            <Nav />
            <div className="main">
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/latestwork" component={LatestWork} />
                    <Route path="/projects/:id" component={LatestWorkDetail} projects={getProjects} />
                </Switch>
            </div>
        </div>
    </BrowserRouter>
};

export default App;
