import React, {useRef, useState} from 'react';
import {Link} from "react-router-dom";
import BackgroundImageOnLoad from 'background-image-on-load';	

interface ILatestWorkBoxProps {
    project: any;
    projectId: number;
}

const LatestWorkBox: React.FC<ILatestWorkBoxProps> = ({ project, projectId }) => {
    const wrapperContainer = useRef<HTMLDivElement>(null);
    const [bgIsLoaded, setBgIsLoaded] = useState(false);

    const [projectImage, setProjectImage] = useState(project.mainImage);

    if (wrapperContainer.current) {
        wrapperContainer.current.style.backgroundImage = project.mainImage;
    }

    if (!projectImage) {
        return <div>Loading</div>;
    }

    return <Link to={`/projects/${projectId}`}>
        <div
            className="wrapper"
            ref={wrapperContainer}
            style={{ backgroundImage: `url(${projectImage}`, transition: 'opacity 0.3s ease', opacity: `${bgIsLoaded ? 1 : 0}`}}
        >
            <BackgroundImageOnLoad
                src={projectImage}
                onLoadBg={() => setBgIsLoaded(true)}
            />
            <div className="shape shape-open">
                <div className="shape-content">
                    <h4>{project.title}</h4>
                    <span dangerouslySetInnerHTML={{ __html: project.shortDescription }} />
                    <div className="link">
                        See more
                    </div>
                </div>
            </div>
        </div>
    </Link>
};

export default LatestWorkBox;
